import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { tokens } from '../theme';
import Button from './Button';
import ButtonLabel from './ButtonLabel';
import { constants } from '../utils/constants';
import Modal from './Modal';
import api from '../services/api';

const InviteCard = ({ data, token }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const BACKGROUND_COLOR = colors.light;
  const HEADER_TEXT_COLOR = colors.light;
  const TEXT_COLOR = colors.dark;
  const DATE = new Date(data.start_date_time);
  const DATE_STR = DATE.toDateString().split(' ');
  const DATE_HOURS = DATE.getHours().toString().padStart(2, '0');
  const DATE_MINUTES = DATE.getMinutes().toString().padStart(2, '0');

  const [loading, setLoading] = useState(false);
  const [acceptStatus, setAcceptStatus] = useState(
    data.invited[0].accept_status || constants.statusEnum.AWAITING_RESPONSE,
  );
  const [denyModal, setDenyModal] = useState(false);

  const onAccept = () => {
    setLoading(true);
    const payload = {
      access_token: token,
      event_id: data.event_id,
      accept_status: constants.statusEnum.ACCEPTED,
    };
    api
      .post('/webeventupdate', payload)
      .then((response) => {
        setAcceptStatus(constants.statusEnum.ACCEPTED);
        setLoading(false);
        navigate(`/status/${constants.statusEnum.ACCEPTED}`);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onDecline = () => {
    setDenyModal(true);
  };

  const onConfirmDecline = () => {
    setDenyModal(false);
    setLoading(true);
    const payload = {
      access_token: token,
      event_id: data.event_id,
      accept_status: constants.statusEnum.DECLINED,
    };
    api
      .post('/webeventupdate', payload)
      .then((response) => {
        setAcceptStatus(constants.statusEnum.DECLINED);
        setLoading(false);
        navigate(`/status/${constants.statusEnum.DECLINED}`);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Box
        position="absolute"
        top="20px"
        width="328px"
        height="624px"
        display="flex"
        justifyContent="flex-start"
        flexDirection="column"
        sx={{
          backgroundColor: BACKGROUND_COLOR,
          borderRadius: '16px',
          boxShadow: '1px 2px 9px #00000075',
        }}
        overflow="hidden"
      >
        <Box width="100%" flex={1}>
          <img
            src={`${data.location.location_img}`}
            alt={data.location.location_postal_address}
            loading="lazy"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />
          <Box
            width="100%"
            height="40px"
            sx={{
              position: 'relative',
              top: '-96%',
              left: '0px',
            }}
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <img
              src={`data:image/png;base64,${data.event_organiser.profile_img}`}
              alt={data.event_organiser.display_name}
              loading="lazy"
              width="40px"
              height="40px"
              style={{
                objectFit: 'cover',
                position: 'relative',
                left: '16px',
                borderRadius: '20px',
              }}
            />
            <Typography variant="h5" color={HEADER_TEXT_COLOR} ml="24px" mr="16px" fontWeight="600">
              {data.event_organiser.display_name} is inviting you for{' '}
              {data.event_name.toLowerCase()}!
            </Typography>
          </Box>
        </Box>
        <Box width="100%" display="flex" flexDirection="column" pb="16px">
          <Box width="100%" display="flex" flexDirection="column" p="16px 16px 0px 16px">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5" color={TEXT_COLOR} fontWeight="600">
                {data.location.location_name}
              </Typography>
              <ButtonLabel
                variant="h7"
                color={colors.blue}
                fontWeight="600"
                sx={{ textDecorationLine: 'underline' }}
                href={constants.url}
              >
                View event
              </ButtonLabel>
            </Box>
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              mt="16px"
            >
              <MapOutlinedIcon htmlColor={colors.blue} fontSize="medium" />
              <Typography variant="h7" color={TEXT_COLOR} fontWeight="600" ml="8px">
                {data.location.location_postal_address}
              </Typography>
            </Box>
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              mt="8px"
            >
              <AccessTimeOutlinedIcon htmlColor={colors.blue} fontSize="medium" />
              <Typography variant="h7" color={TEXT_COLOR} fontWeight="600" ml="8px">
                {Number(DATE_STR[2]) +
                  ' ' +
                  DATE_STR[1] +
                  ' ' +
                  DATE_STR[3] +
                  ' @ ' +
                  DATE_HOURS +
                  ':' +
                  DATE_MINUTES}
              </Typography>
            </Box>
            <ButtonLabel
              variant="h7"
              color={colors.blue}
              fontWeight="400"
              ml="28px"
              sx={{ textDecorationLine: 'underline' }}
              href={constants.url}
            >
              Get the app to suggest alternatives times.
            </ButtonLabel>
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              mt="16px"
            >
              <img
                src={`data:image/png;base64,${data.event_organiser.profile_img}`}
                alt={`${data.event_organiser.display_name}_sub`}
                loading="lazy"
                width="24px"
                height="24px"
                style={{
                  objectFit: 'cover',
                  borderRadius: '12px',
                }}
              />
              <ButtonLabel
                variant="h7"
                color={colors.blue}
                fontWeight="400"
                ml="4px"
                sx={{ textDecorationLine: 'underline' }}
                href={constants.url}
              >
                Get the app to see all invitees
              </ButtonLabel>
            </Box>
          </Box>
          {acceptStatus === constants.statusEnum.AWAITING_RESPONSE &&
            (!loading ? (
              <Box width="100%" display="flex" flexDirection="row" p="12px 12px 0px 12px">
                <Button type="secondary" label="NOPE!" onClick={onDecline} />
                <Button type="primary" label="YEAH!" onClick={onAccept} />
              </Box>
            ) : (
              <Box
                width="100%"
                height="40px"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                mt="36px"
                p="0px 12px 0px 12px"
              >
                <CircularProgress color="secondary" size="24px" />
              </Box>
            ))}
          {acceptStatus === constants.statusEnum.ACCEPTED && (
            <>
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                margin="30px 16px 0px 16px"
              >
                <CheckCircleIcon htmlColor={colors.blue} fontSize="medium" />
                <Typography variant="h5" color={TEXT_COLOR} fontWeight="400" ml="8px">
                  Invite accepted.
                </Typography>
              </Box>
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                mt="4px"
              >
                <Typography variant="h7" color={TEXT_COLOR} fontWeight="400" ml="18px" mt="2px">
                  Why not purpose a
                </Typography>
                <ButtonLabel
                  variant="h7"
                  color={colors.blue}
                  fontWeight="400"
                  ml="4px"
                  sx={{ textDecorationLine: 'underline' }}
                  href={`${constants.url}/event`}
                >
                  change to the event?
                </ButtonLabel>
              </Box>
            </>
          )}
          {acceptStatus === constants.statusEnum.DECLINED && (
            <>
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                margin="30px 16px 0px 16px"
              >
                <CancelOutlinedIcon htmlColor={colors.blue} fontSize="medium" />
                <Typography variant="h5" color={TEXT_COLOR} fontWeight="400" ml="8px">
                  Invite declined.
                </Typography>
              </Box>
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                mt="4px"
              >
                <Typography variant="h7" color={TEXT_COLOR} fontWeight="400" ml="18px" mt="2px">
                  Why not purpose a
                </Typography>
                <ButtonLabel
                  variant="h7"
                  color={colors.blue}
                  fontWeight="400"
                  ml="4px"
                  sx={{ textDecorationLine: 'underline' }}
                  href={`${constants.url}/event`}
                >
                  change to the event?
                </ButtonLabel>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Modal
        open={denyModal}
        handleClose={() => {
          setDenyModal(false);
        }}
        title="Are you sure?"
        onConfirmDecline={onConfirmDecline}
      />
    </>
  );
};

export default InviteCard;
