import { Box, Typography, useTheme, Button as Btn } from '@mui/material';
import { Link } from 'react-router-dom';

import { tokens } from '../theme';

const Button = ({ type, width, label, onClick, route, href }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const PRIMARY = type === 'primary';
  const BACKGROUND_COLOR = PRIMARY ? colors.blue : '#00000000';
  const TEXT_COLOR = PRIMARY ? colors.light : colors.blue;

  return (
    <Box
      width={width}
      maxWidth="322px"
      height="40px"
      maxHeight="40px"
      mt="24px"
      ml="12px"
      mr="12px"
      display="flex"
      flex={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: BACKGROUND_COLOR,
        borderRadius: '8px',
        border: `1px solid ${colors.blue}`,
      }}
    >
      <Btn
        sx={{ width: '100%', height: '100%', textTransform: 'none' }}
        onClick={onClick}
        component={route != null ? Link : undefined}
        to={route != null ? `/${route}` : undefined}
        href={href}
        target={href != null ? '_blank' : '_self'}
      >
        <Typography variant="h5" color={TEXT_COLOR}>
          {label}
        </Typography>
      </Btn>
    </Box>
  );
};

export default Button;
