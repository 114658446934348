import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '../../components/Button';
import { constants } from '../../utils/constants';
import { tokens } from '../../theme';

const Status = () => {
  const params = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const TEXT_COLOR = colors.dark;

  return (
    <Box height="100%" m="20px" display="flex" justifyContent="center" alignItems="center">
      <Box
        width="328px"
        height="624px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        {Number(params.status) === constants.statusEnum.DECLINED ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M70 40C70 56.5685 56.5685 70 40 70C23.4315 70 10 56.5685 10 40C10 23.4315 23.4315 10 40 10C56.5685 10 70 23.4315 70 40ZM40 41.4142L27.3738 54.0404L25.9596 52.6262L38.5858 40L25.9596 27.3738L27.3738 25.9596L40 38.5858L52.6262 25.9596L54.0404 27.3738L41.4142 40L54.0404 52.6262L52.6262 54.0404L40 41.4142Z"
                fill="#222222"
              />
            </svg>
            <Typography
              variant="h3"
              color={TEXT_COLOR}
              fontWeight="700"
              mt="32px"
              textAlign="center"
            >
              Event declined!
            </Typography>
            <Typography
              variant="h5"
              color={TEXT_COLOR}
              fontWeight="300"
              m="16px 0px 24px 0px"
              textAlign="center"
            >
              You have declined via a web invite. Get more with the Let’s Do... mobile app.
            </Typography>
            <Button width="322px" type="primary" label="Download app" href={constants.url} />
            <Button
              width="322px"
              type="secondary"
              label="View event"
              onClick={() => {
                navigate(-1);
              }}
            />
          </>
        ) : (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
            >
              <path
                d="M40.5 7.5C34.0721 7.5 27.7886 9.40609 22.444 12.9772C17.0994 16.5484 12.9338 21.6242 10.4739 27.5628C8.01408 33.5014 7.37047 40.0361 8.62449 46.3404C9.87851 52.6448 12.9738 58.4358 17.519 62.981C22.0643 67.5262 27.8552 70.6215 34.1596 71.8755C40.464 73.1295 46.9986 72.4859 52.9372 70.0261C58.8758 67.5662 63.9516 63.4006 67.5228 58.056C71.0939 52.7114 73 46.4279 73 40C72.9835 31.3855 69.5541 23.1286 63.4627 17.0373C57.3714 10.9459 49.1145 7.51652 40.5 7.5ZM55.9688 34.3125L37.6563 51.8125C37.1828 52.2579 36.5562 52.504 35.9063 52.5C35.5886 52.5046 35.2731 52.4461 34.9781 52.3281C34.6831 52.2101 34.4144 52.0349 34.1875 51.8125L25.0313 43.0625C24.7773 42.8409 24.5708 42.5703 24.4241 42.2669C24.2774 41.9634 24.1935 41.6335 24.1776 41.2968C24.1617 40.9602 24.214 40.6238 24.3314 40.3079C24.4488 39.9919 24.6288 39.703 24.8607 39.4585C25.0927 39.2139 25.3716 39.0188 25.6809 38.8848C25.9901 38.7508 26.3233 38.6807 26.6603 38.6787C26.9973 38.6768 27.3313 38.743 27.642 38.8734C27.9528 39.0038 28.234 39.1957 28.4688 39.4375L35.9063 46.5312L52.5313 30.6875C53.0174 30.2634 53.6486 30.0436 54.293 30.0741C54.9373 30.1046 55.545 30.383 55.9889 30.8511C56.4328 31.3192 56.6785 31.9408 56.6748 32.5858C56.671 33.2309 56.4181 33.8496 55.9688 34.3125Z"
                fill="#1E1E1E"
              />
            </svg>
            <Typography
              variant="h3"
              color={TEXT_COLOR}
              fontWeight="700"
              mt="32px"
              textAlign="center"
            >
              Event accepted!
            </Typography>
            <Typography
              variant="h5"
              color={TEXT_COLOR}
              fontWeight="300"
              m="16px 0px 24px 0px"
              textAlign="center"
            >
              You have accepted via a web invite. Get more with the Let’s Do... mobile app.
            </Typography>
            <Button width="322px" type="primary" label="Download app" href={constants.url} />
            <Button
              width="322px"
              type="secondary"
              label="View event"
              onClick={() => {
                navigate(-1);
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default Status;
