import { Typography, Button as Btn } from '@mui/material';
import { Link } from 'react-router-dom';

const ButtonLabel = ({
  onClick,
  route,
  href,
  variant,
  fontWeight,
  color,
  ml,
  mr,
  sx,
  children,
}) => {
  return (
    <Btn
      sx={{
        textTransform: 'none',
        margin: 0,
        padding: 0,
        marginLeft: ml,
        marginRight: mr,
        justifyContent: 'flex-start',
      }}
      onClick={onClick}
      component={route != null ? Link : undefined}
      to={route != null ? `/${route}` : undefined}
      href={href}
      target={href != null ? '_blank' : '_self'}
    >
      <Typography variant={variant} color={color} fontWeight={fontWeight} sx={sx}>
        {children}
      </Typography>
    </Btn>
  );
};

export default ButtonLabel;
