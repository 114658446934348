import { Box, Typography, useTheme, Modal as Mdl } from '@mui/material';

import { tokens } from '../theme';
import Button from './Button';

const Modal = ({ open, handleClose, title, label, onConfirmDecline }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const BACKGROUND_COLOR = colors.light;
  const TEXT_COLOR = colors.dark;

  return (
    <Mdl
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: BACKGROUND_COLOR,
          minHeight: '136px',
          minWidth: '256px',
          borderRadius: '16px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {title != null && (
          <Typography id="modal-modal-title" variant="h5" color={TEXT_COLOR} fontWeight="600">
            {title}
          </Typography>
        )}
        {label != null && (
          <Typography
            id="modal-modal-description"
            variant="h7"
            color={TEXT_COLOR}
            fontWeight="400"
            mt="8px"
          >
            {label}
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button type="secondary" label="Yes" onClick={onConfirmDecline} />
          <Button type="primary" label="No" onClick={handleClose} />
        </Box>
      </Box>
    </Mdl>
  );
};

export default Modal;
