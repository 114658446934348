import { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';

import InviteCard from '../../components/InviteCard';
import api from '../../services/api';
import { tokens } from '../../theme';
import ButtonLabel from '../../components/ButtonLabel';
import { constants } from '../../utils/constants';

const Invite = () => {
  const params = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const TEXT_COLOR = colors.dark;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const payload = {
      access_token: params.token,
      event_id: params.id,
    };
    api
      .post('/webevent', payload)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box height="100%" m="20px" display="flex" justifyContent="center" alignItems="center">
      {loading ? (
        <CircularProgress color="secondary" />
      ) : data != null ? (
        <InviteCard data={data} token={params.token} />
      ) : (
        <Box height="100%" m="20px" display="flex" justifyContent="center" alignItems="center">
          <Box
            width="328px"
            height="624px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M70 40C70 56.5685 56.5685 70 40 70C23.4315 70 10 56.5685 10 40C10 23.4315 23.4315 10 40 10C56.5685 10 70 23.4315 70 40ZM40 41.4142L27.3738 54.0404L25.9596 52.6262L38.5858 40L25.9596 27.3738L27.3738 25.9596L40 38.5858L52.6262 25.9596L54.0404 27.3738L41.4142 40L54.0404 52.6262L52.6262 54.0404L40 41.4142Z"
                fill="#222222"
              />
            </svg>
            <Typography
              variant="h3"
              color={TEXT_COLOR}
              fontWeight="700"
              mt="32px"
              textAlign="center"
            >
              Oops..!
            </Typography>
            <Typography
              variant="h5"
              color={TEXT_COLOR}
              fontWeight="300"
              m="16px 0px 24px 0px"
              textAlign="center"
            >
              No event found. Check the link provided to you or
              <ButtonLabel
                variant="h5"
                color={colors.blue}
                fontWeight="300"
                ml="4px"
                mr="4px"
                sx={{ textDecorationLine: 'underline' }}
                href={`${constants.url}`}
              >
                download
              </ButtonLabel>
              the app for further options.
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Invite;
