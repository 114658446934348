import { createContext, useState, useMemo } from 'react';
import { createTheme } from '@mui/material/styles';

// color design tokens
export const tokens = (mode) => ({
  ...(mode === 'dark'
    ? {
        yellow: '#FFE95A',
        blue: '#5C7AE4',
        dark: '#1E1E1E',
        light: '#FFFFFF',
      }
    : {
        yellow: '#FFE95A',
        blue: '#5C7AE4',
        dark: '#1E1E1E',
        light: '#FFFFFF',
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === 'dark'
        ? {
            primary: {
              main: colors.dark,
            },
            secondary: {
              main: colors.blue,
            },
            neutral: {
              dark: colors.dark,
              main: colors.dark,
              light: colors.light,
            },
            background: {
              default: '#1E1E1E',
            },
          }
        : {
            primary: {
              main: colors.yellow,
            },
            secondary: {
              main: colors.blue,
            },
            neutral: {
              dark: colors.dark,
              main: colors.light,
              light: colors.light,
            },
            background: {
              default: '#FFE95A',
            },
          }),
    },
    typography: {
      fontFamily: ['Montserrat'],
      fontSize: 12,
      h1: {
        fontFamily: ['Montserrat'],
        fontSize: 40,
      },
      h2: {
        fontFamily: ['Montserrat'],
        fontSize: 32,
      },
      h3: {
        fontFamily: ['Montserrat'],
        fontSize: 24,
      },
      h4: {
        fontFamily: ['Montserrat'],
        fontSize: 20,
      },
      h5: {
        fontFamily: ['Montserrat'],
        fontSize: 16,
      },
      h6: {
        fontFamily: ['Montserrat'],
        fontSize: 14,
      },
      h7: {
        fontFamily: ['Montserrat'],
        fontSize: 12,
      },
    },
  };
};

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState('light');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => setMode((prev) => (prev === 'light' ? 'dark' : 'light')),
    }),
    [],
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
