import { CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route } from 'react-router-dom';

// import Topbar from './pages/global/Topbar';
import { ColorModeContext, useMode } from './theme';
import Invite from './pages/invite';
import Status from './pages/status';

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content">
            {/* <Topbar /> */}
            <Routes>
              <Route path="/" render={null} />
              <Route path="/:id/:token" element={<Invite />} />
              <Route path="/status/:status" element={<Status />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
