export const constants = {
  baseUrl: 'https://api.letsdoapp.com/api/v1.0',
  url: 'https://letsdoapp.com/?utm_source=letsdoweb&utm_medium=digital&utm_campaign=invite&utm_id=8&utm_term=webinvite',
  statusEnum: {
    AWAITING_RESPONSE: 0,
    ACCEPTED: 1,
    ACCEPTED_WITH_EDITS: 2,
    DECLINED_WITH_EDITS: 3,
    DECLINED: 4,
  },
};
